import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  Heading,
  Skeleton,
  Slide,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from "@chakra-ui/react";
import MeetingsList from "./MeetingsList";
import { Database } from "../database.types";
import { createClient } from "@supabase/supabase-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const getNearestMeeting = (meetings: any[]) => {
  return meetings.find((meeting) => {
    return meeting.time_start > Date.now() / 1000;
  });
};

function getPluralForm(
  number: number,
  singularForm: string,
  dualForm: string,
  pluralForm: string
) {
  let remainder100 = Math.abs(number) % 100;
  let remainder10 = remainder100 % 10;

  if (remainder100 > 10 && remainder100 < 20) return pluralForm;
  if (remainder10 > 1 && remainder10 < 5) return dualForm;
  if (remainder10 === 1) return singularForm;

  return pluralForm;
}

const groupMeetingsByDate = (meetings: Meeting[]) => {
  return meetings.reduce((grouped, meeting) => {
    const dateStr = formatDate(meeting.time_start);

    if (!grouped[dateStr]) {
      grouped[dateStr] = [];
    }
    grouped[dateStr].push(meeting);
    return grouped;
  }, {} as { [key: string]: Meeting[] });
};

const isToday = (timestamp: number) => {
  const today = new Date();
  const date = new Date(timestamp * 1000);
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

interface Meeting {
  id: number;
  title: string;
  time_start: number;
  length: number;
  skipped: boolean;
}

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  return (
    date.toLocaleDateString("en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    }) + (isToday(timestamp) ? " — today" : "")
  );
};

const supabase_url = "https://fwvhgvooopsgqogooiwl.supabase.co";
const supabase_key =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ3dmhndm9vb3BzZ3FvZ29vaXdsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQwNzEzMDgsImV4cCI6MjAxOTY0NzMwOH0.8QxBFFzOr3XbEq5gpcO1j4tf0MwJgL0HV3ui2nBf5Lc";

const supabase = createClient<Database>(supabase_url, supabase_key);
function App() {
  const [sleepedMeetings, setSleepedMeetings] = React.useState(null);
  const [sleepedMinutes, setSleepedMinutes] = React.useState(null);
  const { isOpen, onToggle } = useDisclosure();
  const [meetings, setMeetings] = React.useState([]);
  const [groupedMeetings, setGroupedMeetings] = React.useState({} as any);

  const getMeetings = async () => {
    const { data, error } = await supabase.from("meetings").select("*");
    return { data, error };
  };

  const markMeeting = async (id: number, skipped: boolean) => {
    const { data, error } = await supabase
      .from("meetings")
      .update({ skipped: skipped })
      .eq("id", id);
    return { data, error };
  };

  const markMeetingAndUpdateMeetings = (id: number, skipped: boolean) => {
    markMeeting(id, skipped);
    setMeetings(
      meetings.map((meeting) => {
        if ((meeting as any).id === id) {
          return { ...(meeting as any), skipped: skipped };
        }
        return meeting;
      }) as any
    );
  };

  const calculateSleepedMeetings = (meetings: any[]) => {
    return meetings.filter((meeting) => meeting.skipped).length;
  };

  const calculateSleepedMinutes = (meetings: any[]) => {
    return meetings
      .filter((meeting) => meeting.skipped)
      .reduce((acc, meeting) => {
        return acc + meeting.length;
      }, 0);
  };

  useEffect(() => {
    getMeetings().then((res) => {
      if (res.data) {
        setMeetings(
          res.data
            .map((entry) => {
              return {
                ...entry,
                time_start: entry.time_start ?? 0,
              };
            })
            .sort((a, b) => a.time_start - b.time_start) as any
        );
      }
    });
  }, []);

  useEffect(() => {
    setGroupedMeetings(groupMeetingsByDate(meetings));
  }, [meetings]);

  useEffect(() => {
    if (meetings.length > 0) {
      setSleepedMeetings(calculateSleepedMeetings(meetings) as any);
      setSleepedMinutes(calculateSleepedMinutes(meetings) as any);
    }
  }, [meetings]);

  const scrollToNearestMeeting = (meetings: any[]) => {
    const nearestMeeting = getNearestMeeting(meetings);
    console.log(nearestMeeting);
    if (nearestMeeting) {
      const element = document.getElementById(nearestMeeting.id.toString());
      console.log(element);
      element?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    scrollToNearestMeeting(meetings);
  }, [isOpen]);

  return (
    <div
      className="App"
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <HStack h="100vh" w="100%">
        <Center h="100%" w="100%">
          <VStack w={"100%"}>
            <VStack spacing={0}>
              <Heading size={"md"}>
                За 2024 год
                <br />
                Гоша проебал
              </Heading>
              <Skeleton isLoaded={sleepedMeetings !== null}>
                <Heading size="4xl">{sleepedMeetings ?? 0}</Heading>
              </Skeleton>
              <Heading size="s">
                {getPluralForm(
                  sleepedMeetings ?? 0,
                  "встреча",
                  "встречи",
                  "встреч"
                )}
              </Heading>
            </VStack>
            <Skeleton isLoaded={sleepedMinutes !== null}>
              <Text
                color={"GrayText"}
                onClick={() => {
                  onToggle();
                }}
              >
                ...что эквивалентно примерно {sleepedMinutes ?? 100}{" "}
                {getPluralForm(
                  sleepedMinutes ?? 0,
                  "минуте",
                  "минутам",
                  "минутам"
                )}
              </Text>
            </Skeleton>
          </VStack>
        </Center>

        <Button onClick={onToggle} alignSelf={"start"} mt={"16px"} mr={"8px"}>
          <FontAwesomeIcon icon={isOpen ? faChevronRight : faBars} />
        </Button>
        <Box
          w={isOpen ? "400px" : 0}
          h={"100vh"}
          pt={"32px"}
          pb={"32px"}
          overflowX={"hidden"}
          overflowY={"scroll"}
          transition="all 0.5s"
        >
          <Box w={"400px"} h={"100vh"}>
            <MeetingsList
              meetings={groupedMeetings}
              onMarkMeeting={markMeetingAndUpdateMeetings}
            />
          </Box>
        </Box>
      </HStack>
    </div>
  );
}

export default App;
export { getNearestMeeting };
