import { Box, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck, faSlash } from "@fortawesome/free-solid-svg-icons";
import { faSlackHash } from "@fortawesome/free-brands-svg-icons";
import { getNearestMeeting } from "./App";
interface Meeting {
  id: number;
  title: string;
  time_start: number;
  length: number;
  skipped: boolean;
}

interface MeetingsListProps {
  meetings: Record<string, Meeting[]>;
  onMarkMeeting: (id: number, skipped: boolean) => void;
}

const MeetingsList: React.FC<MeetingsListProps> = React.memo(
  ({ meetings, onMarkMeeting }) => {
    const [maxIndex, setMaxIndex] = React.useState(20);

    useEffect(() => {
      //   const nearestMeeting = getNearestMeeting(meetings.flat);
      //   if (nearestMeeting) {
      //     const index = meetings.flat.findIndex(
      //       (meeting) => meeting.id === nearestMeeting.id
      //     );
      //     setMaxIndex(index + 20);
      //   }
    }, []);

    return (
      <VStack spacing={4} align="stretch" mr={"16px"}>
        {Object.keys(meetings)
          .map((date) => (
            <Box key={date} textAlign={"left"}>
              <Text fontSize="xs" textTransform={"uppercase"} mb={2}>
                {date}
              </Text>
              <VStack spacing={2} textAlign={"left"} align={"start"}>
                {meetings[date]
                  .sort((a, b) => a.time_start - b.time_start)
                  .map((meeting) => (
                    <HStack
                      key={meeting.id}
                      textAlign={"left"}
                      id={meeting.id.toString()}
                    >
                      <Text fontWeight="bold">{meeting.title}</Text>
                      <Text>
                        {new Date(meeting.time_start * 1000).toLocaleTimeString(
                          [],
                          { hour: "2-digit", minute: "2-digit", hour12: false }
                        )}
                      </Text>
                      <HStack ml={"32px"} spacing={"12px"} mr={"12px"}>
                        <Tooltip
                          label="Присутствовал"
                          aria-label="Присутствовал"
                        >
                          <FontAwesomeIcon
                            color={!meeting.skipped ? "#000000" : "#cccccc"}
                            icon={faCheck}
                            cursor={"pointer"}
                            onClick={() => {
                              onMarkMeeting(meeting.id, false);
                            }}
                          />
                        </Tooltip>

                        <Tooltip label="Проебал" aria-label="Проебал">
                          <FontAwesomeIcon
                            color={meeting.skipped ? "#000000" : "#cccccc"}
                            icon={faCancel}
                            cursor={"pointer"}
                            onClick={() => {
                              onMarkMeeting(meeting.id, true);
                            }}
                          />
                        </Tooltip>
                      </HStack>
                    </HStack>
                  ))}
              </VStack>
            </Box>
          ))
          .slice(0, 20)}
      </VStack>
    );
  }
);

export default MeetingsList;
